import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic } from '@fortawesome/free-solid-svg-icons';
import {
  Container,
  Row,
  Col,
  Card,
  Accordion,
} from 'react-bootstrap';
import Layout from '../../layouts';

class TermsConditions extends React.Component {
  render() {
    return (
      <Layout page="Terms & Conditions">
        <div
          className="about-faq-area pb-100 bg_cover"
          style={{ backgroundImage: 'url(assets/images/banner-privacy-policy.jpg)' }}
        >
          <Container>
            <Row>
              <Col lg="12">
                <div className="about-faq-content">
                  <h2 className="title">Privacy Policy</h2>
                  <p>*Last Updated: October 23, 2023</p>
                </div>

                <div className="faq-accordion-3 mt-30">
                  <Accordion defaultActiveKey="0">
                    <Card
                      className="card wow fadeInLeft pt-2 pb-2 pl-4 pr-4"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".2s"
                    >
                      <Card.Header
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagic}
                          className="mr-2"
                        />
                        <span>
                          Introduction
                        </span>
                      </Card.Header>
                      <span className='mt-2'>
                        Welcome to Kanindo Consulting Indonesia. We value the privacy and security of our clients and users. This Privacy Policy outlines the types of information we collect, how we use it, and the measures we take to safeguard your data.
                      </span>
                    </Card>
                  </Accordion>
                  <Accordion defaultActiveKey="0">
                    <Card
                      className="card wow fadeInLeft pt-2 pb-2 pl-4 pr-4"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".2s"
                    >
                      <Card.Header
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagic}
                          className="mr-2"
                        />
                        <span>
                          Information Collected
                        </span>
                      </Card.Header>
                      <p className='mt-2'>
                        We may collect the following types of information:
                      </p>
                      <div className='d-flex'>
                        <p className='pr-3'>•</p>
                        <p>
                          Personal identification information: Name, email address, phone number, passport details, etc.
                        </p>
                      </div>
                      <div className='d-flex'>
                        <p className='pr-3'>•</p>
                        <p>
                          Transactional information: Details about the services you've availed and payments.
                        </p>
                      </div>
                      <div className='d-flex'>
                        <p className='pr-3'>•</p>
                        <p>
                          Technical data: IP address, browser type, location, and other related information.
                        </p>
                      </div>
                    </Card>
                  </Accordion>
                  <Accordion defaultActiveKey="0">
                    <Card
                      className="card wow fadeInLeft pt-2 pb-2 pl-4 pr-4"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".2s"
                    >
                      <Card.Header
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagic}
                          className="mr-2"
                        />
                        <span>
                          How We Use Your Information
                        </span>
                      </Card.Header>
                      <p className='mt-2'>
                        We use the information we collect:
                      </p>
                      <div className='d-flex'>
                        <p className='pr-3'>•</p>
                        <p>
                          To process your visa applications and provide services.
                        </p>
                      </div>
                      <div className='d-flex'>
                        <p className='pr-3'>•</p>
                        <p>
                          To communicate with you about your application or our services.
                        </p>
                      </div>
                      <div className='d-flex'>
                        <p className='pr-3'>•</p>
                        <p>
                          To improve our website and user experience.
                        </p>
                      </div>
                      <div className='d-flex'>
                        <p className='pr-3'>•</p>
                        <p>
                          To comply with legal obligations and prevent fraud.
                        </p>
                      </div>
                    </Card>
                  </Accordion>
                  <Accordion defaultActiveKey="0">
                    <Card
                      className="card wow fadeInLeft pt-2 pb-2 pl-4 pr-4"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".2s"
                    >
                      <Card.Header
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagic}
                          className="mr-2"
                        />
                        <span>
                          Data Protection
                        </span>
                      </Card.Header>
                      <span className='mt-2'>
                        We have implemented robust security measures to protect your data from unauthorized access, alteration, or disclosure.
                      </span>
                    </Card>
                  </Accordion>
                  <Accordion defaultActiveKey="0">
                    <Card
                      className="card wow fadeInLeft pt-2 pb-2 pl-4 pr-4"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".2s"
                    >
                      <Card.Header
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagic}
                          className="mr-2"
                        />
                        <span>
                          Third-party Links
                        </span>
                      </Card.Header>
                      <span className='mt-2'>
                        Our website may contain links to other websites. We are not responsible for the privacy practices of those sites.
                      </span>
                    </Card>
                  </Accordion>
                  <Accordion defaultActiveKey="0">
                    <Card
                      className="card wow fadeInLeft pt-2 pb-2 pl-4 pr-4"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".2s"
                    >
                      <Card.Header
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagic}
                          className="mr-2"
                        />
                        <span>
                          Cookies
                        </span>
                      </Card.Header>
                      <span className='mt-2'>
                        We use cookies to enhance user experience, analyze website traffic, and optimize our services. By using our site, you consent to our use of cookies.
                      </span>
                    </Card>
                  </Accordion>
                  <Accordion defaultActiveKey="0">
                    <Card
                      className="card wow fadeInLeft pt-2 pb-2 pl-4 pr-4"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".2s"
                    >
                      <Card.Header
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagic}
                          className="mr-2"
                        />
                        <span>
                          Your Rights
                        </span>
                      </Card.Header>
                      <span className='mt-2'>
                        You have the right to access, correct, or delete your personal information from our records. For any requests or concerns, please contact us.
                      </span>
                    </Card>
                  </Accordion>
                  <Accordion defaultActiveKey="0">
                    <Card
                      className="card wow fadeInLeft pt-2 pb-2 pl-4 pr-4"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".2s"
                    >
                      <Card.Header
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagic}
                          className="mr-2"
                        />
                        <span>
                          Changes to This Policy
                        </span>
                      </Card.Header>
                      <span className='mt-2'>
                        We may update this Privacy Policy from time to time. Any changes will be reflected on this page.
                      </span>
                    </Card>
                  </Accordion>
                  <Accordion defaultActiveKey="0">
                    <Card
                      className="card wow fadeInLeft pt-2 pb-2 pl-4 pr-4"
                      data-wow-duration=".1.2s"
                      data-wow-delay=".2s"
                    >
                      <Card.Header
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faMagic}
                          className="mr-2"
                        />
                        <span>
                          Contact Us
                        </span>
                      </Card.Header>
                      <span className='mt-2' style={{ whiteSpace: 'pre-line' }}>
                        {'If you have any questions regarding this Privacy Policy, please contact us at:\r\nKANINDO CONSULTING INDONESIA\r\nEmail: '}<a style={{color: '#81a3bb'}} href={'mailto:info@kanindoconsulting.com'} target='__blank'>info@kanindoconsulting.com</a>
                      </span>
                    </Card>
                  </Accordion>
                </div>
                {/* faq accordion */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
      </Layout>
    );
  }
}

export default TermsConditions;
