import React from "react";
import Layout from "../../layouts";
import { Container, Row, Col } from "react-bootstrap";
import visa from "../../data/visa";
import parse from "html-react-parser";
import "./Index.scss";
// console.log(this.props.match);
class VisaPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    const filterData = visa.filter(
      (item) =>
        this.props.match.params.data === (item.name ? item.name : item.title)
    );
    this.setState({ data: filterData[0] }, () => {});
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.data !== null &&
      prevProps.match.params.data !== this.props.match.params.data
    ) {
      const filterData = visa.filter(
        (item) =>
          this.props.match.params.data === (item.name ? item.name : item.title)
      );
      this.setState({ data: filterData[0] }, () => {});
    }
  }
  render() {
    const { data } = this.state;
    return (
      <Layout
        page={
          data !== null ? data.name || data.title : this.props.match.params.data
        }
      >
        <div className="page-title-area">
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center">
                  <h2 className="title">
                    {data !== null ? data.title : "Work Details"}
                  </h2>
                  <nav aria-label="breadcrumb">
                    {data !== null ? data.titleDescription : "Visa"}
                  </nav>
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {/*====== PAGE TITLE PART ENDS ======*/}

        {/*====== CASE DETAILS PART START ======*/}
        <div className="case-details-area pt-120 pb-115">
          <Container>
            <Row>
              {data !== null &&
                data.content.map((item, index) => (
                  <div className="case-content-2 pt-40 pb-25">
                    <h4 className="title">{item.title}</h4>
                    {parse(item.contentDescription)}
                  </div>
                ))}
            </Row>
          </Container>
          <>
            <div
              className={
                data?.images != null && data?.images.length === 3
                  ? "image-container-haha"
                  : "imagesContainer"
              }
            >
              {data != null &&
              data?.images != null &&
              data?.images.length === 1 &&
              data?.card == null ? (
                data?.images.map((item, idx) => (
                  <div>
                    <img
                      alt={`visa-${
                        item !== null && item !== undefined ? item : "image"
                      }`}
                      style={{
                        borderRadius: "8px",
                        marginRight: "1rem",
                        width: "500px",
                        height: "100%",
                        float: "left",
                      }}
                      src={`/../assets/imageProduct/${item}`}
                    />
                  </div>
                ))
              ) : data != null &&
                data?.images != null &&
                data?.images.length === 1 &&
                data?.card.length == 1 ? (
                data?.images.map((item, idx) => (
                  <div className="flexbox">
                    <div className="item">
                      <div className="content">
                        <img
                          alt={`visa-${
                            item !== null && item !== undefined ? item : "image"
                          }`}
                          style={{
                            borderRadius: "8px",
                            marginRight: "1rem",
                            width: "500px",
                            height: "100%",
                            float: "left",
                          }}
                          src={`/../assets/imageProduct/${item}`}
                        />
                      </div>
                    </div>
                    {data?.card?.map((it, idx) => (
                      <div className="item">
                        <div className="content">
                          <div className="card">
                            <div className="card-content">{parse(it)}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))
              ) : data != null &&
                data?.images != null &&
                data?.images.length == 1 &&
                data?.card.length > 1 ? (
                <div className="sowo">
                <div className="sodiq">
                  {data?.card?.map((it, idx) => (
                      <div className="item">
                        <div className="content">
                          <div className="card">
                            <div className="card-content">{parse(it)}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                {data?.images.map((item, idx) => (
                  <div className="flexbox-mantap">
                    <div className="item">
                      <div className="content">
                        <img
                          alt={`visa-${
                            item !== null && item !== undefined ? item : "image"
                          }`}
                          style={{
                            borderRadius: "8px",
                            marginRight: "1rem",
                            width: "500px",
                            height: "100%",
                            float: "left",
                          }}
                          src={`/../assets/imageProduct/${item}`}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              ) : data?.images != null &&
                data?.images.length === 2 &&
                data?.card == null ? (
                data?.images.map((item, idx) => (
                  <div>
                    <img
                      alt={`visa-${
                        item !== null && item !== undefined ? item : "image"
                      }`}
                      style={{
                        borderRadius: "8px",
                        marginRight: "1rem",
                        width: "500px",
                        height: "100%",
                        float: "left",
                      }}
                      src={`/../assets/imageProduct/${item}`}
                    />
                  </div>
                ))
              ) : data?.images != null &&
                data?.images.length === 2 &&
                data?.card.length > 0 ? (
                <div className="wawa">
                  <div className="item">
                    <div className="content">
                      <div className="card">
                        <div className="card-content">
                          {parse(data?.card[0])}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sunam">
                    {data?.images.map((item, idx) => (
                      <div className="marginimage">
                        <img
                          alt={`visa-${
                            item !== null && item !== undefined ? item : "image"
                          }`}
                          style={{
                            borderRadius: "8px",
                            marginRight: "1rem",
                            width: "500px",
                            height: "100%",
                            float: "left",
                          }}
                          src={`/../assets/imageProduct/${item}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : data?.images != null && data?.images.length === 3 ? (
                <>
                  <img
                    alt={`visa-${
                      data.images[0] !== null && data.images[0] !== undefined
                        ? data.images[0]
                        : "image"
                    }`}
                    className="full-width-images"
                    src={`/../assets/imageProduct/${data.images[0]}`}
                  />
                  <div className={"half-width-image"}>
                    <img
                      alt={`visa-${
                        data.images[1] !== null && data.images[1] !== undefined
                          ? data.images[1]
                          : "image"
                      }`}
                      className={"half-width-image"}
                      style={{
                        borderRadius: "8px",
                        marginRight: "1rem",
                        width: "500px",
                        height: "100%",
                        float: "left",
                      }}
                      src={`/../assets/imageProduct/${data.images[1]}`}
                    />
                    <img
                      alt={`visa-${
                        data.images[2] !== null && data.images[2] !== undefined
                          ? data.images[2]
                          : "image"
                      }`}
                      className={"half-width-image"}
                      style={{
                        borderRadius: "8px",
                        marginRight: "1rem",
                        width: "500px",
                        height: "100%",
                        float: "left",
                      }}
                      src={`/../assets/imageProduct/${data.images[2]}`}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </>
          <div className="containerCardData">
            {data != null &&
              data?.images == null &&
              data.card != null &&
              data.card.map((item, idx) => (
                <div className="containerCard">
                  <div className="card">
                    <div className="card-content">{parse(item)}</div>
                  </div>
                </div>
              ))}
          </div>
          <div className="containerCardData">
            {data != null && data.button != null && (
              <div className="containerCard">
                <div className="card">
                  <div className="card-content">{parse(data.button)}</div>
                </div>
              </div>
            )}
          </div>
          {data !== null &&
            data?.content2?.map((item, idx) => (
              <div className="case-content-3 pt-40 pb-40 mt-25">
                <Container>
                  <h4 className="title">{item.title}</h4>
                  {parse(item.contentDescription)}
                </Container>
              </div>
            ))}
        </div>
      </Layout>
    );
  }
}

export default VisaPage;
