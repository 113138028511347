import ReactGA from "react-ga4";

const event = (eventData, isPathName) => {
  ReactGA.initialize("G-YTZ5VJ41JL")
  if (isPathName) {
    const optionsOrName = handlePageName(eventData);
    ReactGA.send({ hitType: "pageview", page: eventData, title: optionsOrName });
  }
};

const handlePageName = (page) => {
  if (page === "/") {
    return "Home";
  } else {
    const arrPage = page?.split("/");
    const pageName = arrPage[arrPage?.length - 1];
    const output = pageName.replace(/\b\w/g, (x) => x.toUpperCase());
    return output;
  }
};

export { event };
