import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Index.scss';

class AboutIntroPart extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal = (event) => {
    this.setState({ isOpen: true });
    event.preventDefault();
  };

  render() {
    return (
      <div className="about-intro-area pt-120">
        <Container>
          <Row>
            <Col lg="12">
              <div>
                <h2 className="title">Services</h2>
                <p>
                  Kanindo Consulting began in 2012 with a simple goal
                  in mind: to improve expatriate services in
                  Indonesia. Visa consultants, legal practitioners,
                  and relocation agencies in Indonesia were
                  untrustworthy, charged exorbitant fees, and were not
                  transparent about their pricing strategies and
                  working practises, which we wanted to overcome and
                  improve.
                </p>
                <p >
                  We wanted to combine Western efficiency with Eastern
                  hospitality to give our customers the best of both
                  worlds. As a multinational team, we thrived on
                  incorporating our personal experiences and knowledge
                  into what we do, and we worked tirelessly together
                  to create an agency unlike any other in Indonesia.
                </p>
                <p>
                  We are proud to be the first agency in Indonesia to
                  truly provide ethical and transparent information.
                  Please click on the link below that is most relevant
                  to your current interests.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
                <div style={{ marginTop: '2rem' }}>
                  <div>
                    <h2 className="title">Vision</h2>
                    <p>
                      “To be the Best Consultant as the Services
                      Provider of company establishments, immigrations
                      and visa handlings in Indonesia”
                    </p>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <h2 className="title">Mission</h2>
                    • To improve the skill and quality of employees{' '}
                    <br />
                    • To provide excellent service to clients <br />
                    • To improve and maintain valued assets of the
                    company <br />
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <h2 className="title">Object</h2>
                    <p>
                      To provide quality service according to service
                      standard and maximum satisfaction of the
                      customers.
                    </p>
                  </div>
                  <div style={{ marginTop: '2rem' }}>
                    <h2 className="title">Motto</h2>
                    <p>
                      Tranquility and client satisfaction is our
                      happiness
                    </p>
                  </div>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AboutIntroPart;
