import React from 'react';
import Layout from '../../layouts';
import { Container, Row, Col } from 'react-bootstrap';
import news from '../../data/news';
import parse from 'html-react-parser';
// console.log(this.props.match);
class NewsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    const filterData = news.filter(
      (item) => this.props.match.params.data === item.title
    );
    this.setState({ data: filterData[0] }, () => {});
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.data !== null &&
      prevProps.match.params.data !== this.props.match.params.data
    ) {
      const filterData = news.filter(
        (item) => this.props.match.params.data === item.title
      );
      this.setState({ data: filterData[0] }, () => {});
    }
  }
  render() {
    const { data } = this.state;
    console.log(data);
    return (
      <Layout page={this.props.match.params.data}>
        <div className="page-title-area">
          <Container>
            <Row>
              <Col lg="12">
                <div className="page-title-item text-center">
                  <h2 className="title">
                    {data !== null ? data?.title : 'Work Details'}
                  </h2>
                  <nav aria-label="breadcrumb">
                    {data !== null ? data?.timeline : 'Visa'}
                  </nav>
                </div>
                {/* page title */}
              </Col>
            </Row>
            {/* row */}
          </Container>
          {/* container */}
        </div>
        {/*====== PAGE TITLE PART ENDS ======*/}

        {/*====== CASE DETAILS PART START ======*/}
        <div className="case-details-area pt-120 pb-115">
          <Container>
            <Row>
              {data !== null && (
                <div className="case-content-2 pt-40 pb-25">
                  <img
                    alt={`news-${data.title}`}
                    style={{ width: '100%', marginBottom: '25px' }}
                    src={`../../../assets/images/${data.img}`}
                  />
                  {parse(data.content)}
                </div>
              )}
            </Row>
          </Container>
        </div>
      </Layout>
    );
  }
}

export default NewsPage;
