export const company = [
  {
    title: "PMA Company Establishment",
    subTitle: "Indonesian Visa & Immigration Specialists",
    imageContent: "company-1.jpeg",
    images: ["company-1.png"],
    /*card: [
      `<h1>SETUP A FOREIGN OWNED COMPANY (PMA)</h1><h4>&nbsp;IN JAKARTA</h4></p><h1><strong>17.500.000 IDR</strong></h1><br><h4>Price Includes</h4><br>
• Company’s deed of establishment<br>
• Ratification from the Ministry of Law and Human Right<br>
• Tax Registration Number (NPWP)<br>
• Business License<br>
• Business Location Permit<br>
• Business Identification Number (NIB)<br>
• Virtual Office lease contract for 1 year<br>
• Notification of Registration (SKT) from the tax office<br>
• OSS Account<br>
`,
    ],*/
    content: [
      {
        title: "",
        description: `<p style="text-align: justify;">For businessmen or prospective foreign investors who will open a business in Indonesia or invest their capital in a company in Indonesia or may be interested in having their own company, they can establish a legal entity in the form of a PMA or Foreign Direct Investment /FDI Company. Furthermore, foreign investors as the founders of this PMA/FDI Company are allowed to own 100% of their shares. A share ownership of 100% by foreigners is only allowed for certain types of business, such as trading and consulting. Furthermore, shareholders in this FDI company are given facilities to get a work permit and Stay Permit/KITAS directly for 2 years without having to pay DPKK funds of US$ 2400 to the government, but those who are allowed to work are only for the position of President director, while for the position of commissioner, they are not allowed to work even though they are also given a Temporary Stay Permit/KITAS which is also valid for 2 years. Thus, shareholders in FDI companies can save funds of US$ 2400 per 2 years when compared to holders of Temporary Stay Permit/KITAS as ordinary employees (as employees) only.
            PT Penanaman Modal Asing or PMA, is a limited liability company established under Indonesian law, in which there is participation or use of foreign capital, either using fully foreign capital or joint venture capital with Indonesian citizens or domestic national companies.<br>Foreign capital means capital that is sourced and owned by foreign countries, individual foreign citizens, foreign business entities, foreign legal entities, and/or Indonesian legal entities whose capital is partly or entirely owned by foreign parties.
            Foreign investment that will enter Indonesia has been regulated by the Indonesian government and must go through foreign investment procedures in accordance with the regulations specified by the government, which in this case is the Investment Coordinating Board (Badan Koordinasi Penanaman Modal, BKPM).
            The important thing for foreign investment is the provision of Negative Investment List (Daftar Negatif Investasi, DNI) which regulates the maximum percentage of foreign ownership in a business activity</p>`,
      },
      {
        title: "How long is the validity period of the company's management?",
        description: `<p style="text-align: justify;">According to the law, the validity period of the composition of the management of a Limited Liability Company (PT) is limited to a maximum of 5 years, and after 5 years, a new management must be selected through a general meeting of shareholders. Thereby, the management of Limited Liability Company (PT) after 5 years will dissolve automatically. However, the company’s legality period remains valid and the company can remain legally carrying out various business activities.</p>
            <br>
            <p><strong>Mandatory requirements in the establishment of a PT include:</strong></p>
            <ol>
            <li>Residential Identification Card (KTP/ Passport) and Tax Identification Number (NPWP) (if individual) and legal entity documents (if PT, Foundation, etc.) of the shareholders.</li>
            <li>Residential Identification Card (KTP/Passport) and Tax Identification Number (NPWP) of the Management, in this case those who will serve as directors and commissioners.</li>
            <li>Office lease letter or proof of ownership of office premises, the location of which must be in accordance with office zoning/commercial zoning/mixed zoning.</li>
            <li>Office photo, if needed.</li>
            </ol>
            <br>
            <p><strong>The company legal documents that will be obtained are:</strong></p>
            <ol>
            <li>&nbsp;Deed of establishment</li>
            <li>Certificate of Legalization from the Ministry of Law &amp; Human Rights</li>
            <li>Company Tax Identification Number (NPWP)</li>
            <li>Notification of Registration (SKT) from the Tax Office</li>
            <li>Company Business Identification Number (NIB)</li>
            <li>Business License, from OSS</li>
            <li>Location Permit, from OSS</li>
            </ol>`,
      },
      {
        title: "Legal Basis of PT",
        description: `<ol>
            <li><strong>Law No. 40/2007 – Limited Liability Company (UUPT)</strong> </li>
            </ol>
            <p>This Law (UUPT) is the main legal source for regulating Limited Liability Companies in Indonesia</p>
            <br>
            <ol start="2">
            <li><strong>Law No. 1/1995 – Capital Market</strong> </li>
            </ol>
            <p>Regulating the terms and conditions of parties (one of which is PT) and capital market instruments.</p>
            <br>
            <ol start="3">
            <li><strong>Law No. 25/2007 Investment</strong> </li>
            </ol>
            <p>Regulating ways and procedures for investment by both local and foreign investors</p>
            <br>
            <ol start="4">
            <li><strong>Presidential Regulation No. 27/1998 – Merger, Consolidation, Takeover of PT</strong> </li>
            </ol>
            <p>Regulating ways and procedures for merging, consolidating and taking over a PT</p>
            <br>
            <ol start="5">
            <li><strong>Presidential Regulation No. 43/2011 – Procedure for Submission and Use of PT Name</strong> </li>
            </ol>
            <p>Regulating that the local PT name must use Indonesian Language.</p>
            <br>
            <ol start="6">
            <li><strong>Presidential Regulation No. 47/2012 – Corporate Social and Environmental Responsibility of PT (CSR)</strong> </li>
            </ol>
            <p>Whereas PT that carries out activities related to natural resources is obliged to carry out CSR activities.</p>
            <br>
            <ol start="7">
            <li><strong>Presidential Regulation No. 7/2016 – Amendment to the Authorized Capital of PT</strong> </li>
            </ol>
            <p>Whereas specifically for businesses with MSME criteria, the Authorized Capital is determined according to the agreement. Then, 25% of the Authorized Capital must be paid up and placed.</p>
            <br>
            <ol start="8">
            <li><strong>Regulations of Ministry of Laws and Human Rights No. 14/2020 – Procedures for Submission of Application for Legal Entity and Approval of Amendment to Articles of Association and Submission of Notification of Amendment to Articles of Association and Amendment to Data of PT</strong></li>
            </ol>
            <p>Regulating the procedures for requesting approval and amendments to the articles of association as well as documents necessary to be submitted to the Minister through the Legal Entity Administration System (SABH).</p>
            <br>
            <ol start="9">
            <li><strong>Regulations of Ministry of Laws and Human Rights No. M.HH-02.AH.01.01 of 2010 – Procedure for PT Announcement in the State Gazette and Supplement to the State Gazette of the Republic of Indonesia</strong> </li>
            </ol>
            <p>Regulating the procedure for announcement in the State Gazette and Supplement to State Gazette on the establishment, dissolution, plan for distribution of assets resulting from liquidation and expiration of the legal entity status of the Company.</p>
            <br>
            <ol start="10">
            <li><strong>Presidential Regulations No. 44/2016 – Regulation concerning DNI</strong> </li>
            </ol>
            <p>DNI = Regulations regarding the maximum percentage of foreign ownership in a business field. An example of DNI is trading activities that are not affiliated with production, so the maximum foreign ownership is 67%.</p>`,
      },
    ],
  },
  {
    title: "PT Company Establishment",
    subTitle: "Indonesian Visa & Immigration Specialists",
    imageContent: "company-2.jpeg",
    images: ["company-2.png"],
    /*card: [
      `<h1>SETUP A PT COMPANY</h1><h4>&nbsp;IN JAKARTA</h4><h1>13.000.000 IDR</h1><br><h4>Price Includes</h4><br>
• Company’s deed of establishment<br>
• Ratification from the Ministry of Law and Human Right<br>
• Tax Registration Number (NPWP)<br>
• Business License<br>
• Business Location Permit<br>
• Business Identification Number (NIB)<br>
• Virtual Office lease contract for 1 year<br>
• Notification of Registration (SKT) from the tax office<br>
• OSS Account<br><br>
<h4>Processing Time</h4><br>
• 20 Working days`,
    ],*/
    content: [
      {
        title: "",
        description: `<p style="text-align: justify;">Limited Liability Company or PT (Perseroan Terbatas) is one of the most frequently used business entities by entrepreneurs in Indonesia to start their business. Even though in the last few years, the procedures for establishing a PT and for obtaining business licenses are always changing and following the dynamics of government policies. Especially now for the establishment of companies, especially those in the form of PT, with the publication of the latest Industrial Standard Industrial Classification (ISIC), all companies that have been around for a long time must also make adjustments by making renewal of deeds. It aims to follow the Law on Job Creation that has been ratified/enacted, which has been adjusted to its level of risk.</p>
            <p style="text-align: justify;">For the establishment of a PT, a minimum of 2 or more founders is required which will occupy the positions of directors and commissioners, while they can also be shareholders at the same time. Shareholders in the establishment of a PT do not always have to be individuals; it can also be a legal entity.</p>`,
      },
      {
        title: "How long is the validity period of the company's management?",
        description: `<p style="text-align: justify;">According to the law, the validity period of the composition of the management of a Limited Liability Company (PT) is limited to a maximum of 5 years, and after 5 years, a new management must be selected through a general meeting of shareholders. Thereby, the management of Limited Liability Company (PT) after 5 years will dissolve automatically. However, the company’s legality period remains valid and the company can remain legally carrying out various business activities.</p>
            <br>
            <p><strong>Mandatory requirements in the establishment of a PT include:</strong></p>
            <ol>
            <li>Residential Identification Card (KTP) and Tax Identification Number (NPWP) (if individual) and legal entity documents (if PT, Foundation, etc.) of the shareholders.</li>
            <li>Residential Identification Card (KTP) and Tax Identification Number (NPWP) of the Management, in this case those who will serve as directors and commissioners.</li>
            <li>Office lease letter or proof of ownership of business/office premises, the location of which must be in accordance with office zoning/commercial zoning/mixed zoning.</li>
            <li>Office photo, if needed.</li>
            </ol>
            <br>
            <p><strong>The company legal documents that will be obtained are:</strong></p>
            <ol>
            <li>Deed of establishment</li>
            <li>Certificate of Legalization from the Ministry of Law &amp; Human Rights</li>
            <li>Company Tax Identification Number (NPWP)</li>
            <li>Notification of Registration (SKT) from the Tax Office</li>
            <li>Company Business Identification Number (NIB)</li>
            <li>Business License, from OSS</li>
            <li>Location Permit, from OSS</li>
            </ol>
            <br>
            <p style="text-align: justify;">The length of the PT Establishment process will be adjusted to the line of business/ISIC taken. For a PT Establishment with a low-risk trading business field/ISIC, it will only take 7 working days (after the Deed of Establishment of PT has been executed). However, for a PT Establishment with a high-risk field/ISIC, it will take longer time to meet the licensing standards.</p>
            <p><strong>Kanindo Consulting</strong>, is committed to always providing all the convenience and quickness in order to provide satisfaction to the clients.</p>
            <p>For PT Establishment Services, we can help you especially in the entire Jabodetabek area.</p>`,
      },
      {
        title: "Legal Basis of PT",
        description: `<ol>
            <li><strong>Law No. 40/2007 – Limited Liability Company/UUPT</strong> </li>
            </ol>
            <p>This Law (UUPT) is the main legal source for regulating Limited Liability Companies in Indonesia</p>
            <br>
            <ol start="2">
            <li><strong>Law No. 1/1995 – Capital Market</strong></li>
            </ol>
            <p>Regulating the terms and conditions of parties (one of which is PT) and capital market instruments.</p>
            <br>
            <ol start="3">
            <li><strong>Law No. 25/2007 Investment</strong> </li>
            </ol>
            <p>Regulating ways and procedures for investment by both local and foreign investors</p>
            <br>
            <ol start="4">
            <li><strong>Presidential Regulation No. 27/1998 – Merger, Consolidation, Takeover of PT</strong> </li>
            </ol>
            <p>Regulating ways and procedures for merging, consolidating and taking over a PT</p>
            <br>
            <ol start="5">
            <li><strong>Presidential Regulation No. 43/2011 – Procedure for Submission and Use of PT Name</strong> </li>
            </ol>
            <p>Regulating that the local PT name must use Indonesian Language.</p>
            <br>
            <ol start="6">
            <li><strong>Presidential Regulation No. 47/2012 – Corporate Social and Environmental Responsibility of PT (CSR)</strong> </li>
            </ol>
            <p>Whereas PT that carries out activities related to natural resources is obliged to carry out CSR activities.</p>
            <br>
            <ol start="7">
            <li><strong>Presidential Regulation No. 7/2016 – Amendment to the Authorized Capital of PT</strong> </li>
            </ol>
            <p>Whereas specifically for businesses with MSME criteria, the Authorized Capital is determined according to the agreement. And, 25% of the Authorized Capital must be paid up and placed.</p>
            <br>
            <ol start="8">
            <li><strong>Regulations of Ministry of Laws and Human Rights No. 14/2020 – Procedures for Submission of Application for Legal Entity and Approval of Amendment to Articles of Association and Submission of Notification of Amendment to Articles of Association and Amendment to Data of PT</strong></li>
            </ol>
            <p>Regulating the procedures for requesting approval and amendments to the articles of association as well as documents necessary to be submitted to the Minister through the Legal Entity Administration System (SABH).</p>`,
      },
    ],
  },
  {
    title: "CV Company Establishment",
    subTitle: "Indonesian Visa & Immigration Specialists",
    imageContent: "company-3.jpeg",
    /*images: ["company-3.png"],*/
    images: null,
    card: null,
    content: [
      {
        title: "",
        description: `<p style="text-align: justify;"><span>Commanditaire Vennotschap (Limited Partnership) or better known as CV is an alliance or cooperation established by a person who entrusts his money or goods to another person to run his business. In CV Establishment, there are 2 alliances called active partner and passive partner.</span></p>
            <p style="text-align: justify;">Active partners are people who run the business and are responsible for every activity of the company, which include making company policies and decisions. Active partners can also be referred to as administrators in the establishment of CV.</p>
            <p style="text-align: justify;">In contrast to active partners, passive partners are partners who invest their capital in a company. In other words, if the company suffers a loss, the passive partner is only responsible for the capital that he has invested.</p>
            <p style="text-align: justify;">CV is a business entity that is not a legal entity, in which this business entity does not have the status as <em>rechtspersoon</em> like business entities with legal entities such as PT, foundations, and Cooperatives.</p>
            <p style="text-align: justify;">Thereby, it is not possible for CV name to be registered in a land certificate or proof of ownership of a motor vehicle. Even if these assets become assets that are included (<em>inbreng</em>) by the founders in the CV, they are still registered in the name of the individual owner.</p>
<br>
<p><strong>The advantages of establishing a CV are:</strong></p>
<ol>
<li>It is a solution for entrepreneurs who want to have a business but with limited capital.</li>
<li>The opportunity to develop a business is greater because the CV form can obtain credit more easily.</li>
<li>Business management and division of responsibilities are better because they are managed by complementary partners who have expertise in their fields.</li>
</ol>
<br>
<p><strong>Disadvantages of establishing a CV are:</strong></p>
<ol>
<li>All responsibility for the company’s setbacks and losses will be transferred to the complementary partner (active administrator), where the entirety of this loss may involve his personal property.</li>
<li>Since it is not a company in the form of a legal entity, it is necessary to make a proper management system in the CV in order to ensure the business running.</li>
</ol>
<br>
<p><strong>Mandatory requirements in the establishment of a CV include:</strong></p>
<ol>
<li>Residential Identification Card and Tax Identification Number of the Administrators</li>
<li>Office lease letter or proof of ownership of business/office premises, the location of which must be in accordance with office zoning/commercial zoning/mixed zoning.</li>
<li>Office photo, if needed.</li>
</ol>
<br>
<p><strong>The company legal documents that will be obtained are:</strong></p>
<ol>
<li>Deed of Establishment of CV</li>
<li>Decree of Ministry of Laws and Human Rights</li>
<li>Company Tax Identification Number (NPWP)</li>
<li>Notification of Registration (<em>Surat Keterangan Terdaftar</em>, SKT) from the Tax Office</li>
<li>Company Business Identification Number (NIB)</li>
<li>Business License, from OSS</li>
<li>Location Permit, from OSS</li>
</ol>
<br>
<p><strong>Kanindo Consulting</strong>, is committed to always providing all the convenience and quickness in the process of CV Establishment in order to provide satisfaction to the clients.</p>`,
      },
      {
        title: "Legal Basis of CV",
        description: `<ol>
            <li><strong>1</strong><strong>. </strong><strong>KUHD/Law of Commercial Law</strong></li>
            </ol>
            <p><strong>&nbsp;&nbsp;&nbsp; </strong>This Law (KUHD) is the main legal source for regulating CV in Indonesia</p>
            <ol>
            <br>
            <li><strong> </strong><strong>Regulations of Ministry of Laws and Human Rights No. 17/2018</strong></li>
            </ol>
            <p>(Registration of Limited Partnership, Firm and Civil Company)</p>
            <p style="text-align: justify;">In accordance with the provisions of Article 3 sub-article (2) of the Regulation of Ministry of Law and Human Rights No. 17/2018 relating to the establishment of a CV, this regulation requires business actors to register a CV through a system managed by the Ministry of Law and Human Rights. This is different from the provisions for CV registration before the enactment of the Regulation of Ministry of Law and Human Rights No. 17/2018 which requires business actors to apply for registration to the District Court (<em>Pengadilan Negeri</em>, PN) based on the domicile of the CV.</p>`,
      },
    ],
  },
  {
    title: "Representative Office (KPPA)",
    subTitle: "Indonesian Visa & Immigration Specialists",
    imageContent: "company-4.jpeg",
    /*images: ["company-4.png"],*/
    images: null,
    card: null,
    content: [
      {
        title: "",
        description: `<p><strong>Why Do You Need to Establish a Representative Office in Indonesia?</strong></p>
            <p style="text-align: justify;">Establishing a representative office in Indonesia is the right step to try to see the potential of business in Indonesia in the future without having to spend huge amount of investment capital.</p>
            <p style="text-align: justify;">The government has prepared and provided many facilities and conveniences to prospective foreign investors. One of them is by allowing foreign companies to open their representative offices in Indonesia before they actually invest heavily in Indonesia by establishing a company in the form of PT, PMA. Thereby, the main purpose of establishing a representative office in Indonesia is to test the water before the actual establishment of the company.</p>
            <p style="text-align: justify;">Unlike the representative office of foreign trade (<em>Kantor Perwakilan Perdagangan Asing</em>, KP3A), the representative office of foreign companies (<em>Kantor Perwakilan Perusahaan Asing</em>, KPPA) is only allowed to operate at the maximum of 5 years and it cannot be extended. After 5 years, the legal entity must be changed to a PMA company. Thereby, a representative office can conduct market research, approach potential clients, and build brand awareness of your business. However, this company is not allowed to carry out business transactions and issue invoices since activities that generate profits are not allowed. This is the main factor that distinguishes between representative companies in Indonesia from other legal entities such as Limited Liability Company (<em>Perseroan Terbatas</em>, PT), Foreign Investment (<em>Penanaman Modal Asing</em>, PMA), and Domestic Investment (<em>Penanaman Modal Dalam Negeri, </em>PMDN).</p>
            <br>
            <p><strong>Type – Foreign Representative Office </strong></p>
<ol>
<li>KPPA – Kantor Perwakilan Perusahaan Asing (Foreign Company Representative Office)</li>
<li>KP3A – Kantor Perwakilan Perdagangan Asing (Foreign Trade Representative Office)</li>
</ol>
<br>
<p><strong>KPPA – Kantor Perwakilan Perusahaan Asing (Foreign Company Representative Office)</strong></p>
<p style="text-align: justify;">Is a representative office to carry out promotional activities and analyze the market before establishing a PMA. The validity period of the permit is at the maximum of 5 years and cannot be renewed after 5 years since the company must switch to Foreign Investment (PMA) or must be closed.</p>
<p style="text-align: justify;">For KPPA, you can simply attach a Letter of Appointment, Letter of Statement, Letter of Attorney. Also, Letter of Intent and Letter of Reference from the Embassy are not required<strong>.</strong></p>
<br>
<p><strong>KP3A – Kantor Perwakilan Perdagangan Asing (Foreign Trade Representative Office)</strong></p>
<p style="text-align: justify;">Is a representative office to carry out promotional activities of trades and analyze the market. Firstly, you will obtain business license for 2 months, then business license for 1 year, then a permanent permit that is valid for 3 years, which and can be extended every 3 years indefinitely. It is not required to change to PMA.</p>
<p style="text-align: justify;">For KP3A Trade, Letter of Appointment, Letter of Statement, Letter of Attorney, Letter of Intent and Letter of Reference from the Embassy are required.</p>
<br>
<p><strong>Benefits of Representative Office in Indonesia</strong></p>
<br>
<p><strong>Low Establishment Cost</strong></p>
<p style="text-align: justify;">As we explained at the beginning, having a representative office in Indonesia is the right way as you can carry out a market research in Indonesia at a low cost. You can collect the data necessary to convince your target audience or the presence of your business partners who can supply raw materials for the products you are about to produce. Furthermore, these business partner companies will help you understand the Indonesian market really well.</p>
<br>
<p><strong>Becoming Visa Sponsor</strong></p>
<p style="text-align: justify;">The existence of a representative office in Indonesia can help you to become a sponsor/guarantor for residence visa applications for foreigners who will be the leaders of the company and also for foreign employees who will work in your company in Indonesia. Although representative offices are not permitted to carry out transactions and generate income, by having complete corporate legality, the representative office reserves the right to sponsor visa applications and residence permits for all foreigners who will work or who will visit their offices.</p>`,
      },
    ],
  },
  {
    title: "Foundation Setup",
    subTitle: "Indonesian Visa & Immigration Specialists",
    imageContent: "company-5.jpeg",
    images: null,
    card: null,
    content: [
      {
        title: "",
        description: `<p style="text-align: justify;">Foundation is a legal entity consisting of separated assets and is intended to achieve certain goals in the social, religious, and humanitarian fields, which contains no members. Foundations are not a business entity and is non-commercial in nature. Therefore, foundations must not make any profit. In addition, there are no business licenses issued for foundation legal entities. In accordance with the provisions, foundations must be engaged in social, religious and humanitarian fields. An example is the Foundation for Children with Disabilities (Yayasan Penyandang Anak Cacat, YPAC), which is engaged in the humanitarian field. There are various organs inside a foundation. Among them are the founding, supervisory, and management boards. Within the management structure, there must also be the positions of Chairman, Secretary and Treasurer.</p>
            <br>
            <p style="text-align: justify;"><strong>Why Choose Foundations?</strong><br>Foundations are used as a legal forum for organizations engaged in social, religious, and humanitarian fields.</p>
            <br>
            <p style="text-align: justify;"><strong>Who are the founders of a Foundation?</strong><br>The founders of a Foundation can be both Indonesian citizens and foreigners. Specifically for establishment by foreigners, more specific provisions will apply.</p>
            <br>
            <p><strong>What are foundation business models?</strong><br>Foundations are social/non-commercial in nature. As a result, you cannot conduct business.</p>`,
      },
      {
        title: "Legal Basis of Foundation",
        description: `<ol>
            <li>Law No. 16/2001</li>
            </ol>
            <p>Law of Foundation. This Law is the main legal source for regulating foundations in Indonesia</p>
            <br>
            <ol start="2">
            <li>Law No. 28/2004</li>
            </ol>
            <p>Amendment to Law. No. 16/2001, this law partially amends the provisions of the Law of Foundation No. 16/2001</p>
            <br>
            <ol start="3">
            <li>Presidential Regulations No. 63/2008</li>
            <br>
            <li>This Presidential Regulations specify in more detail how the Foundation is regulated in Indonesia, according to article 6. Presidential Regulations No. 63/2008.</li>
            <br>
            <li>Foundation established by Indonesian citizens, the minimum initial wealth must be IDR 10,000,000.-, while Foundations established by foreigners or jointly with foreigners, the minimum initial wealth must be IDR 100,000,000.-</li>
            </ol>`,
      },
    ],
  },
  {
    title: "Business Licensing",
    subTitle: "Indonesian Visa & Immigration Specialists",
    images: null,
    card: null,
    imageContent: "company-6.jpeg",
    content: [
      {
        title: "",
        description: `<p><strong>How to Obtain a Business License in Indonesia</strong></p>
            <p style="text-align: justify;">Currently, the business climate in Indonesia is getting better and more exciting for business actors. It is because the government continues to take breakthrough steps to attract foreign investors to come and invest in Indonesia. After foreign investors have completed the establishment of their companies in Indonesia, some business fields have been allowed to operate or carry out their business activities in accordance with the business purposes and objectives stated in the deed of establishment of the company. Provided that the deed of establishment has been approved by the Ministry of Law &amp; Human Rights, and the company has obtained a Tax Identification Number (NPWP) card and NIB (company registration number) as well as a business license from the OSS.</p>
            <br>
            <p style="text-align: justify;">However, for some types of businesses, it is not enough and they are still required to obtain additional operational permits from other government agencies. For example, the following are some of the business fields that require additional operational permits from special agencies: Freight forwarding services, Construction services, Distributors, Air/Aviation transportation services, export activities &amp; purchases and sales of rough diamonds or diamonds, insurance, restaurant business, cafes, hotels, alcohol sales. Furthermore, companies in the manufacturing industry outside of oil, gas and geothermal must have an industrial business license.</p>
            <br>
            <p style="text-align: justify;">In order to obtain a business license, you can apply for it yourself or be assisted by a consultant you appoint and trust. Currently, to register a business and obtain a basic business license, you must use the Online Single Submission (OSS) system. OSS is a <em>one-stop-shop</em> for obtaining business licenses. However, for certain types of businesses, you cannot obtain the business license simply by applying through the OSS, you are also required to come to the Investment Coordinating Board (BKPM) office to apply in person.</p>
            <br>
            <p style="text-align: justify;"><strong>Kanindo Consulting</strong> can help you to obtain operational license for your business. Our consultant team is very experienced in managing various business licenses so that the process of obtaining operational permits will be faster</p>`,
      },
    ],
  },
  {
    title: "Virtual & Serviced Office Space",
    subTitle: "Indonesian Visa & Immigration Specialists",
    images: null,
    card: null,
    imageContent: "company-7.jpeg",
    content: [
      {
        title: "",
        description: `<p>Don’t have an address for your Indonesian company ?</p>
<br>
            <p><strong>Kanindo Consulting</strong> can provide a domicile address ( Registered company address ) which is includes :</p>
            <ol>
            <li>Company domicile, address at very well-known Jakarta business location</li>
            <li>Certificate of domicile from building management</li>
            <li>Electricity cost </li>
            <li>Internet connection </li>
            <li>Telephone answering and receive letters your belonging </li>
            <li>WIFI and drinking</li>
            <li>Free meeting room 60 hours / year</li>
            </ol>
            <br>
            <p><strong>Price Starting From</strong>&nbsp; :&nbsp;&nbsp; 5,500,000 IDR / year&nbsp; for PMA Company,&nbsp; and&nbsp; 3,500,000 IDR / year for Local PT Company</p>
            <br>
            <p style="text-align: justify;">When you decide to register a company in Indonesia, you need to present the location of your office to obtain a domicile letter. This letter is one of the initial requirements to establish a company in Indonesia.</p>
            <br>
            <p style="text-align: justify;">A virtual office enables investors to obtain a registered address and domicile letter without renting office space. Your business will be registered in one of our offices in Jakarta based on your location. Your logo will be clearly displayed at the entrance, and our team will handle your calls and mail.</p>
            <br>
            <p><em>For further information you are welcome to contact us …………………</em></p>`,
      },
    ],
  },
  {
    title: "Trademark Registration",
    subTitle: "Indonesian Visa & Immigration Specialists",
    imageContent: "company-8.jpeg",
    /*images: ["company-5.png"],*/
    images: null,
    card: null,
    content: [
      {
        title: "",
        description: `<p style="text-align: justify;">In the context of business, trademarks are a crucial thing for every person and company. It constitutes a name and symbol used on products for the purpose of marketing the products in the form of goods and services. These symbols are useful to indicate the origin of goods and/or services. These symbols are useful in showing the origin of goods and/or services, as well as promoting commercial companies engaged in and providing such goods and/or services. In the market share, names and symbols are recognized as trademark (brand), business name, and company name.</p>
            <br>
            <p style="text-align: justify;">In the trade of goods and services, trademarks constitute an intellectual property and has strategic and important value for manufacturers and consumers. For manufacturers, in addition to functioning as a distinguishing factor between their products and other similar products, trademark is also intended to build a company image in product marketing. Meanwhile, in terms of production, trademarks, in addition to facilitating the identification of the origin of products and services, also act as a symbol of prestige/self-esteem. It is because subsequently, trademarks can function as a warranty of the quality of a product.</p>
            <br>
            <p>In order to carry out the function as a distinguishing factor, trademarks must contain the following elements:</p>
<ol>
<li>&nbsp;A sign with distinguishing factors</li>
<li>The sign must be used</li>
<li>For the trade of goods and services</li>
</ol>
<br>
<p style="text-align: justify;"><strong>Indonesia regulates legal protection regarding trademarks in law number 20 of 2016 concerning trademarks</strong>. In that law, a trademark is defined as a sign that can be displayed graphically in the form of images, logos, names, words, letters, numbers, color composition in 2 (two) and/or 3 (three) dimensions, sound, holograms or a combination of 2 (two) or more of these elements in order to distinguish goods and/or services produced by individuals or legal entities in the activities of trading goods and/or services.</p>
<br>
<p>We can assist you in registering your trademark with a simpler process and you can obtain the proof of registration receipt from the government quicker.</p>
        
        
        `,
      },
      {
        title: "What needs to be prepared for trademark registration?",
        description: `<p>To register a trademark, you must prepare the following:</p>
            <ol>
            <li>Trademark name (Brand name)</li>
            <li>Logo image (in PNG/JPEG format)</li>
            <li>Details of the specifications of the goods to be registered, for all types of goods/products.</li>
            <li>Include the following documents:</li>
            </ol>
            <br>
            <p><strong>Registration in the name of an individual.</strong></p>
            <ol>
            <li>Residential Identification Card (KTP) or Passport for foreigners</li>
            <li>Phone Number &amp; Email.</li>
            <li>Address of the trademark’s owner (address in Indonesia)</li>
            </ol>
            <br>
            <p><strong>Registration in the name of a Legal Entity/Company.</strong></p>
            <ol>
            <li>Deed of Establishment</li>
            <li>Certificate of Legalization of Deed from the Ministry of Law &amp; Human Rights</li>
            <li>Latest Amendment to the Deed &amp; its Certificate of Legalization (if any)</li>
            <li>Residential Identification Card (<em>Kartu Tanda Penduduk</em>, KTP)/Temporary Stay Permit (<em>Kartu Izin Tinggal Terbatas</em>, KITAS) of the President Director</li>
            </ol>`,
      },
      {
        title:
          "How long does it take to obtain a trademark registration certificate?",
        description: `<p>The processing time for obtaining a receipt from the government will take no later than 7 days after all the required documents are completed. Then, to obtain a certificate of ownership for the trademark, you will have to wait for about 8 months.</p>`,
      },
    ],
  },
  {
    title: "Company Closure",
    subTitle: "Indonesian Visa & Immigration Specialists",
    imageContent: "company-9.jpeg",
    images: null,
    card: null,
    content: [
      {
        title: "Dissolution of Limited Liability Company",
        description: `<p style="text-align: justify;">The dissolution or closure of a company under Indonesian law means the process of removing the existence of a company’s legal status as a legal entity. It means that with the removal of the legal entity status of the company, all activities along with the existence of the company under the law will be over.</p>
            <br>
            <p style="text-align: justify;">The process of establishment and closure of a company must be carried out legally. Company closure is regulated in the Law of Limited Liability Company No. 40 of 2007 article 142 sub-article (1) concerning termination of activities, liquidation, and termination of the company’s status as a legal entity.</p>
            <br>
            <p>In order to close a company in Indonesia, you must go through a liquidation process.</p>
            <br>
            <p style="text-align: justify;">Liquidation, in other words the clearing and settlement of company assets and liabilities, is the only way to remove the legal existence of any active and inactive businesses in Indonesia.</p>
            <br>
            <p style="text-align: justify;">Who can be a liquidator during the process of company’s dissolution? Legal consultants, lawyers, or Board of Directors.</p>
            <br>
            <p style="text-align: justify;">Specifically for the dissolution of Foreign Investment Company (<em>Penanaman Modal Asing</em>, PMA), it refers to Regulation of Investment Coordinating Board (<em>Badan Koordinasi Penanaman Modal</em>, BKPM) No. 3 of 2012 concerning procedures for investment control and implementation. The process for the dissolution of local Limited Liability Company (<em>Perseroan Terbatas</em>, PT) and PT PMA is almost similar, with the exception that PMA is required to apply for revocation of business license from Investment Coordinating Board (BKPM).</p>
<br>
<p>In general, the reasons for closing a company are due to the following:</p>
<br>
<p>According to Article 142 sub-article (1) of Law Number 40 of 2007 concerning Limited Liability Companies (“UUPT”), stating that the termination of a company shall be due to the followings:</p>
<ol>
<li>Based on the decision of the General Meeting of Shareholders (“GMS”);</li>
<li>Because the period of establishment stipulated in the articles of association has expired;</li>
<li>Based on a court order;</li>
<li>Due to the revocation of bankruptcy based on a decision of a commercial court that has permanent legal force, the bankrupt assets of the company is not sufficient to pay the bankruptcy costs;</li>
<li>Because the bankrupt assets of the company that has been declared bankrupt are in a state of insolvency, as regulated in the Law on Bankruptcy and Suspension of Debt Payment Obligations; or</li>
<li>Due to the revocation of the company’s business license, so that it requires the company to conduct liquidation in accordance with the laws and regulations.</li>
</ol>`,
      },
      {
        title: "How to Dissolve a Limited Liability Company (PT)?",
        description: `<p style="text-align: justify;">A company’s dissolution based on the resolution of the GMS is proposed by the Board of Directors, the Board of Commissioners or 1 (one) shareholder or more representing at least 1/10 (one tenth) of the total shares with voting rights. The resolution of the GMS regarding the dissolution of the company shall be valid if it is taken based on deliberation to reach consensus and/or attended by at least (three quarters) of the total shares with voting rights present or represented at the GMS and approved by at least (three quarters) of the total votes issued.</p>
<br>
<p style="text-align: justify;">In the event that the dissolution of the company occurs based on the resolution of the GMS, the period of establishment stipulated in the articles of association has expired or due to the revocation of bankruptcy based on the decision of the commercial court, the GMS does not appoint any liquidator, the Board of Directors shall act as liquidator. The dissolution of the company must be followed by liquidation carried out by a liquidator or curator; and the company cannot take any legal action, except in terms of settling all company affairs in relation to the liquidation. Then, if it turns out that the members of the Board of Directors, the Board of Commissioners and the Company violate such regulation, they may be subject to legal responsibility jointly and severally.</p>
<br>
<p>Subsequently, in Article 142 sub-article (2) of Law Number 40 of 2007, it indicates that the dissolution of the Company shall:</p>
<ol>
<li>Be followed by a liquidation carried out by a liquidator or curator,</li>
<li>Then, the Company cannot take any legal action, unless it is necessary to settle all company affairs in the context of liquidation.</li>
</ol>
<br>
<p>In general, the process of dissolving a company will take between 1 and 1.5 years to complete.</p>`,
      },
      {
        title:
          "We can act as a liquidator to assist the liquidation process of any company intending to close its business operation in Indonesia",
        description: `<p style="text-align: justify;">For the company’s dissolution occurring due to a revocation of bankruptcy, the commercial court may at the same time decide to dismiss the curator in accordance with the provisions of the Law on Bankruptcy and Suspension of Debt Payment Obligations.</p>
            <br>
            <p>The District Court may dissolve the company for the following reasons:</p>
            <ol>
            <li>Upon petition of the prosecutor based on the reason that the company violates the public interest or the company commits an act that violates the laws and regulations;</li>
            <li>Upon petition of the interested party based on the reasons for the existence of a legal defect in the deed of establishment;</li>
            <li>Upon petition of the shareholders, the Board of Directors or the Board of Commissioners based on the reason that the company cannot be continued.</li>
            </ol>
            <br>
            <p style="text-align: justify;">The liquidator has the obligation to notify all creditors with regards to the dissolution of the company by announcing the dissolution of the company in the Newspapers and State Gazette of the Republic of Indonesia within a period of no later than 30 (thirty) days from the date of dissolution of the company. The notification to the creditor contains:</p>
            <ol>
            <li>matters regarding the dissolution of the company and its legal basis;</li>
            <li>name and address of the liquidator;</li>
            <li>procedures for filing invoices; and</li>
            <li>time period for filing invoices.</li>
            </ol>
            <br>
            <p style="text-align: justify;">To the extent the notification of the dissolution of the company is not carried out in accordance with Article 147 of the Law of Limited Liability Company, the dissolution of the company shall not apply to third parties and the dissolution of the company shall not result in the company losing its legal entity status until the liquidation is competed and the liability of the liquidator is accepted by the GMS or the court. As a result of the dissolution of the company, every letter issued by the company shall include the word “in the process of liquidation” after the company’s name.</p>`,
      },
    ],
  },
];

export default company;